'use client'
import { useEffect, useState } from 'react';
import { detectDesktop } from '@/app/common/Util';

export const useDeviceRestriction = () => {
    const { isDesktop, isSafari, isWindow } = detectDesktop();
    // const allowDesktop = isSafari;
    const [allowedDevice, setAllowedDevice] = useState(!isDesktop || isSafari || isWindow);
    // console.log( allowedDevice );
    
    useEffect(() => {
        // console.log('page load allowedDevice')
        // console.log( allowDesktop );
        // console.log( isDesktop );
        if( isSafari || !isDesktop || isWindow ){
            setAllowedDevice(true);
        }
    }, []);

    return {
        allowedDevice
    }
}