'use client'
import { useEffect, useState } from 'react';
import { detectDesktop } from '@/app/common/Util';

export const OrientationStatus = () => {
    const {isDesktop} = detectDesktop();

    const getOrientation = () => {
        try {
            return  window.screen.orientation.type;
        } catch (e) {
            return "PORTRIAT";
        }
    }

    const checkOrientation = () => {
        try {
            return window.screen.orientation.type.toUpperCase().indexOf("LANDSCAPE") >= 0;
        } catch (e) {
            return false;
        }
    }

    const updateOrientation = () => {
        setOrientation(getOrientation());
    };

    const [orientation, setOrientation] = useState(getOrientation());
    const [isLandscape, setLandscape] = useState(isDesktop ? false : checkOrientation());

    useEffect(() => {
        window.addEventListener('resize', updateOrientation);
        return () => {
            window.removeEventListener('resize', updateOrientation);
        };
    }, []);

    useEffect(() => {
        if( !isDesktop ){
            setLandscape(checkOrientation())
        }
    }, [orientation])    
    
    
    return {
        orientation,
        isLandscape
    }
}