export const TextInvert = "#F9F8F6";
export const SurfacePurple = "#461E5B";
export const SurfaceYellow = "#F9CA11";
export const FooterTextFeildBorder = "#27231B80";
export const TextPrimary = "#27231B";
export const TextPrimary30 = "#27231B4C";
export const Text50="rgba(39, 35, 27, 0.5)";
export const BorderPrimary ="#413A2E";
export const Netural="#F6F2E9";
export const NeturalText = "rgba(39, 35, 27, 0.75)";
export const SurfacePrimary="#F9F8F6"
export const WHITE="#FFFFFF";
export const SHIPPING_INPUT_BORDER="#EEEFF0";
export const BLACK="#000000";
export const LANDSCAPE_DIALOG_BACKGROUND="#27231BCC";