'use client'
import React from "react";

import { styled } from "@mui/material/styles";
import { Dialog, Box , useMediaQuery} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import { SurfaceYellow, SurfacePurple } from "@/app/common/color";
import Canvas from "@/app/components/BlankCanvas";
import PositionBox from "@/app/components/PositionBox";
import { useCountUp } from 'use-count-up'

interface LoadingDialogProps {
  open: boolean;
  duration: number;
  onClose: () => void;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: SurfacePurple,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: SurfaceYellow,
  },
}));

const LoadingDialog: React.FC<LoadingDialogProps> = ({ open, onClose, duration=0.8 }) => {
  const [isCounting, setCounting] = React.useState(false);
  const countUp = useCountUp({
    isCounting: isCounting,
    end: 98,
    duration: duration,
  });

  const resetCounting = () => {
    setCounting(false);
    countUp.value = 0;
    countUp.reset();
    setCounting(true);
  }
  React.useEffect(() => {
    if (open) {
      resetCounting();
    } else {
      countUp.value = 0;
    }
  }, [open]);
  const ipadProBreakpoint = useMediaQuery('(min-width: 1024px)')
  return (
    <Dialog fullScreen open={open} onClose={onClose} 
    PaperProps={{
        style: {
          backgroundImage:'url("/images/background.jpeg")',
        }
      }}>
      <Canvas>

        <div style={{position: 'relative',width: '100vw',height: '100vh',}}>
          <div style={{position: 'absolute',top: '45%',left: '50%',transform: 'translate(-50%, -50%)' , textAlign: 'center'}}
          >
            <Box
            component="img"
            style={
              (ipadProBreakpoint) ? 
              { width: "400px", padding: 0 } : 
              { width: "119px", padding: 0 }
            }
            alt="Oh My Ink Logo"
            src="/images/oh_my_ink_logo_loading.png"
          />
          <Box style={{ width: "50vw", padding: 0 }}>
            <BorderLinearProgress variant="determinate" value={countUp.value as number} />
          </Box></div>
        
        </div>
      </Canvas>
    </Dialog>
  );
};

export default LoadingDialog;
